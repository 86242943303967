<template>
  <div>
    <Loader />
    <div>
      <b-navbar toggleable="lg" type="light" variant="white" class="fixed-top">
        <b-navbar-brand href="#" style="margin-left:200px">
          <div>
            <b-img :src="'https://i.ibb.co/D4tcMVG/logokemendikbud.png'" height="64px"></b-img>
          </div>

        </b-navbar-brand>

        <b-collapse id="nav-collapse" is-nav style="margin-right:200px">

          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <b-nav-form>
              <b-button variant="outline-secondary" pill width="50%" v-b-modal.modal-1>
                <i class="fa fa-search"></i> Cari buku</b-button>
            </b-nav-form>

            <div class="nav navbar-nav">
              <router-link :to="{ name: 'portal.home' }" class="nav-item nav-link" active-class="active" exact>
                Home
              </router-link>
              <router-link :to="{ name: 'portal.favorit' }" class="nav-item nav-link" active-class="active" exact>
                Favorit
              </router-link>

            </div>

          </b-navbar-nav>

        </b-collapse>
      </b-navbar>

      <b-modal id="modal-1" size="lg" hide-footer>
        <template #modal-header="{}">
          <!-- Emulate built in modal header close button action -->

          <div class="input-group input-group-lg">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-lg"><i class="fa fa-search"></i></span>
            </div>
            <input type="text" v-model="input" class="form-control" aria-label="Large"
              aria-describedby="inputGroup-sizing-sm">
          </div>
          <!-- <b-button size="sm" variant="outline-danger" @click="close()">
            Close Modal
          </b-button> -->
        </template>

        <!-- <div class="item fruit" >
          <p>{{ data.judul_buku }}</p>
        </div> -->
        <b-list-group v-if="(input != null)">
          <b-list-group-item style="cursor: pointer;" class="flex-column align-items-start"
            v-for="(data,index) in filteredList()" :key="index" @click="close(data.id)">
            <b-row>
              <b-col cols="2">
                <img class="img-fluid rounded" style="width:80px" :src="data.nama_file_cover" alt="">
              </b-col>
              <b-col cols="10">
                <div class="d-flex w-100 justify-content-between">
              
              <h5 class="mb-1">{{ data.judul_buku }}</h5>
              <small>{{ data.tahun }}</small>
            </div>

            <p class="mb-1">
              {{ data.penulis }}
            </p>
              </b-col>
            </b-row>
            
          </b-list-group-item>
        </b-list-group>
        <div class="item error" v-if="input && !filteredList().length">
          <p>No results found!</p>
        </div>
      </b-modal>

      <!-- <div style="z-index: 999;position:relative" class="item fruit" v-for="fruit in filteredList()" :key="fruit">
        <p>{{ fruit }}</p>
      </div>
      <div class="item error" v-if="input && !filteredList().length">
        <p>No results found!</p>
      </div> -->
    </div>
    <section class="sign-in-page" style="margin-top:80px">
      <div class="container p-0">
        <div class="row no-gutters height-self-center">
          <div class="col-sm-12 align-self-center page-content rounded">
            <div class="row m-0">
              <div class="col-sm-12 sign-in-page-data">
                <router-view></router-view>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import Loader from '../../components/core/loader/Loader'
import logo from '../../assets/images/logo.png'
import { core } from '../../config/pluginInit'
import bgImage from '../../assets/images/login/2.jpg'
import axios from 'axios'
export default {
  name: 'PortalLayout',
  components: {
    Loader
  },
  mounted() {
    this.loadBuku()
    core.index()
  },
  methods: {
    close(dataid){
      this.$router.push("/portal/detailbuku/" + dataid);
      this.$router.go();
    },
    loadBuku() {
      axios.get('https://buku-backend.mysurvey.id/buku').then(response => {
        this.dataBuku = response.data.data
      })
    },
    filteredList() {
      return this.dataBuku.filter((fruit) =>
        fruit.judul_buku.toLowerCase().includes(this.input.toLowerCase())
      )
    }
  },
  data() {
    return {
      input: null,
      dataBuku: [],
      slickOptions: {
        slidesToShow: 1,
        arrows: false
      },
      logo: logo,
      bgImageURL: bgImage
    }
  }
}
</script>
<style>
@import url("../../assets/css/custom.css");
</style>